<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			name: 'analisis_basico',
			form: {
				path: 'Generico',
				reestructuradoRefinanciado: '',
				reestructuradoRefinanciadoComentarios: '',
				rarRentabilidad: '',
				comentarioDetalleAccionistas: '',
				conflictosEntreAccionistas: '',
				movimientoSignificativoAccionario : '',
				comentariosConflictosEntreAccionistas: '',
				comentariosMovimientoSignificativoAccionario : '',
				respaldoCreditoAccionistas: '',
				comentariosRespaldoCreditoAccionistas: '',
				experienciaAdministracion: '',
				comentariosExperienciaAdministracion: '',
				agresividadTomaDecisionesRiesgo: '',
				tipoAdministracion: '',
				comentariosFiliales: '',
			},
			filialesForm: [],
			filiales: [],
		}
	},

	computed: {
		group() {
			if(this.client) {
				return this.clienById(this.client.groupId)
			}
			return null
		},


		showShareholders() {
			let data = this.shareholders.filter(sh => {
				return sh.nombre && sh.participacion
			})

			return data
		},
		...mapGetters({
			clienById: 'arceClients/getById',
			showHelp: 'arceStatus/showHelp'
		})
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		showFilialModal() {
			let filiales = this.filiales.map(f => {return {...f}})
			this.$set(this, 'filialesForm', [...filiales])
			this.showModal('#filialModal')
		},
		getClients() {
			let children = this.client.children.map(ch => {
				return ch.name
			})
			return children
		},
		getHolder(shareholder, name) {
			let client = shareholder.clientes.find(c => c.nombre == name)
			if(!client) {
				return 0
			}
			return client.participacion
		},

		getHolderTotal(name) {
			let shareholders = this.getShareholders()

			let sum = 0

			shareholders.forEach(sh => {
				sh.clientes.forEach(c => {
					if(c.nombre == name) {
						sum += Number(c.participacion)
					}
				})
			})

			return sum
		},
		getShareholders() {
			let shareholders = []
			this.client.children.forEach(ch => {
				if(!ch.hasOwnProperty('arce')) {
					return
				}

				if(!ch.arce.hasOwnProperty('analisis_basico')) {
					return
				}

				let shs = [...ch.arce.analisis_basico.shareholders]

				shs.forEach(s => {
					let shareholder = {...s}

					if(!shareholder.nombre) {
						return
					}

					let alredy = shareholders.find(sh => sh.nombre == shareholder.nombre) 
					// {"nombre":"","participacion":"","financialSponsor":"","ano":"","query":""}
					if(!alredy) {
						shareholders.push({
							nombre: shareholder.nombre,
							clientes: [
								{
									nombre: ch.name,
									participacion: shareholder.participacion,
									financialSponsor: shareholder.financialSponsor,
									ano: shareholder.ano,
								}
							]
						})

						return
					}	
					
					alredy.clientes.push({
						nombre: ch.name,
						participacion: shareholder.participacion,
						financialSponsor: shareholder.financialSponsor,
						ano: shareholder.ano,
					})
				})

			})
			return shareholders
		},
		calcFill() {
			let fields = Object.keys(this.form)
			if(this.form.reestructuradoRefinanciado == 'NO') {
				this.form.reestructuradoRefinanciadoComentarios = ''
				let index = fields.indexOf('reestructuradoRefinanciadoComentarios')
				fields.splice(index, 1)
			}
			if(this.form.conflictosEntreAccionistas == 'NO') {
				this.form.comentariosConflictosEntreAccionistas = ''
				let index = fields.indexOf('comentariosConflictosEntreAccionistas')
				fields.splice(index, 1)
			}
			if(this.form.movimientoSignificativoAccionario == 'NO') {
				this.form.comentariosMovimientoSignificativoAccionario = ''
				let index = fields.indexOf('comentariosMovimientoSignificativoAccionario')
				fields.splice(index, 1)
			}
			if(this.form.respaldoCreditoAccionistas == 'NO') {
				this.form.comentariosRespaldoCreditoAccionistas = ''
				let index = fields.indexOf('comentariosRespaldoCreditoAccionistas')
				fields.splice(index, 1)
			}

			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		addNew() {
			this.filialesForm.push(
				{
					nombre: '',
					participacion: '',
					actividad: '',
					clienteBBVA: '',
					query: ''
				}
			)
		},
		deleteItem(index) {
			this.filialesForm.splice(index, 1)
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},

		saveAllSubsidiary() {
			let filiales = this.filialesForm.map(f => {return {...f}})
			this.$set(this, 'filiales',  [...filiales])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'The record has been saved successfully'
			})
		},

		save() {
			let vm = this
			let group = {...this.client}
			group.arceFill[this.name] = this.calcFill()
			group.arce[this.name] =  Object.assign({...this.form}, {filiales: [...this.filiales]})
			vm._updateArce(group)
			// vm.$emit('updateArce')
		},
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}
			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					path: 'Generico',
					reestructuradoRefinanciado: '',
					reestructuradoRefinanciadoComentarios: '',
					rarRentabilidad: '',
					comentarioDetalleAccionistas: '',
					conflictosEntreAccionistas: '',
					movimientoSignificativoAccionario : '',
					comentariosConflictosEntreAccionistas: '',
					comentariosMovimientoSignificativoAccionario : '',
					respaldoCreditoAccionistas: '',
					comentariosRespaldoCreditoAccionistas: '',
					experienciaAdministracion: '',
					comentariosExperienciaAdministracion: '',
					agresividadTomaDecisionesRiesgo: '',
					tipoAdministracion: '',
					comentariosFiliales: '',
				})
				this.$set(this, 'filiales', [])

				return 
			}
			this.$set(this, 'filiales', [...data.filiales])
			delete data['filiales']
			this.$set(this, 'form', data)
		},
		closeModal() {
			this.hiddenSteps()
			this.fillForm()
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		}),

		...mapActions({
			_updateArce: 'arceDetails/updateArce'
		})
		// ...mapMutations({
		// 	setShareholders: 'arceDetails/setShareholders'
		// })
	},
	mounted() {
		$('#formAnalysisGroupModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formAnalysisGroupModal').off('hide.bs.modal', this.closeModal)
	}

	
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formAnalysisGroupModal')">
				Editar
			</button>
		</div>
		<div>
			<div class="s-collapse__header" data-toggle="collapse" data-target="#abg_1" aria-expanded="true" aria-controls="abg_1">
				<div class="d-flex">
					<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Path</span></h5>
				</div>
			</div>
			<div id="abg_1" class="collapse show" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-12 col-md-6">
						<div class="h-100 py-2">
							<p class="m-0"><span class="text-danger">*</span> Template</p>
							<div>
								<select class="form-control form-control--disabled" disabled name="" v-model="form.path">
									<option value=""></option>
									<option value="Generico">Generico</option>
									<option value="Construcción">Construcción</option>
									<option value="Hoteles">Hoteles</option>
									<option value="Agro">Agro</option>
									<option value="Retail">Retail</option>
									<option value="Auto Retail">Auto Retail</option>
								</select>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_2" aria-expanded="true" aria-controls="abg_m_2">
				<div class="d-flex">
					<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Cromo Grupo</span></h5>
				</div>
			</div>
			<div id="abg_2" class="collapse" aria-labelledby="headingOne">
			</div>
			<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_3" aria-expanded="true" aria-controls="abg_3">
				<div class="d-flex">
					<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Identificaciones</span></h5>
				</div>
			</div>
			<div id="abg_3" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap mx-n2">
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Nombre del Grupo</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="group.name">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">País de Cabecera</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="group.pais_cabecera">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Número de Grupo Económico</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="group.code">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Sector (asset allocation)</p>
							<input class="form-control form-control--disabled" disabled type="text">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Cliente desde</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="group.clientSince">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">¿Tiene marca de Leverage el análisis anterior?</p>
							<input class="form-control form-control--disabled" disabled type="text" value="SI">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Clasificación de Grupo</p>
							<input class="form-control form-control--disabled" disabled type="text" value="">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Grupo Cualitativo</p>
							<input class="form-control form-control--disabled" disabled type="text" value="SI">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block">
							<p class="m-0"><span class="text-danger">*</span> Grupo Reestructurado o refinanciado</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="form.reestructuradoRefinanciado">
						</label>
						<div v-if="form.reestructuradoRefinanciado == 'SI'" class="py-2">
							<label class="d-block m-0">
								<p class="m-0"> <span class="text-danger">*</span> Comentarios sobre el grupo reestructurado o refinanciado</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.reestructuradoRefinanciadoComentarios">
								</textarea>
							</label>
						</div>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Reserva (%)</p>
							<input class="form-control form-control--disabled" disabled type="text" value="12 %">
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">RAR - Rentablilidad</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.rarRentabilidad">
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_4" aria-expanded="true" aria-controls="abg_4">
				<div class="d-flex">
					<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Accionistas</span></h5>
				</div>
			</div>
			<div id="abg_4" class="collapse" aria-labelledby="headingOne">
				<div class="py-3 px-2">
					<table class="s-table w-100">
						<thead class="text-center">
							<tr>
								<th>Accionista</th>
								<th v-for="(c) in getClients()">
									{{c}}
								</th>
							</tr>
						</thead>
						<tbody class="text-center">
							<tr v-for="sh in getShareholders()">
								<td>
									{{sh.nombre}}
								</td>
								<td v-for="(c) in getClients()">
									{{getHolder(sh, c)}}%
								</td>
							</tr>
							<tr>
								<td>TOTAL</td>
								<td v-for="(c) in getClients()">
									{{getHolderTotal(c)}}%
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex flex-wrap mx-n2">
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Comentario Detalle Accionista</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentarioDetalleAccionistas">
							</textarea>
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> ¿Posibles discrepancias / conflictos entre los accionistas? </p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.conflictosEntreAccionistas">
							
						</label>
						<div class="py-2" v-if="form.conflictosEntreAccionistas == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios Posibles discrepancias / conflictos entre los accionistas</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosConflictosEntreAccionistas">
								</textarea>
							</label>
						</div>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> ¿Movimientos significativos en el accionario del grupo? </p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.movimientoSignificativoAccionario">
						</label>
						<div class="py-2" v-if="form.movimientoSignificativoAccionario == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios Movimientos significativos en el accionario del grupo</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosMovimientoSignificativoAccionario">
								</textarea>
							</label>
						</div>
					</div>
					<div class="col-4  py-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> ¿Respaldo de los Créditos por parte de loas Accionistas?</p>
							<select class="form-control form-control--disabled" disabled type="text" v-model="form.respaldoCreditoAccionistas">
								<option value=""></option>
								<option value="NO">NO</option>
								<option value="Accionistas">Accionistas</option>
								<option value="Grupo">Grupo</option>
								<option value="Garantía de activos">Garantía de activos</option>
								<option value="Garantía hipotecaria">Garantía hipotecaria</option>
								<option value="Desconocido">Desconocido</option>
							</select>
						</label>
					</div>
					<div class="col-4  py-2" v-if="form.respaldoCreditoAccionistas !== 'NO'">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Comentarios sobre el apoyo de los accionistas</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosRespaldoCreditoAccionistas">
							</textarea>
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_5" aria-expanded="true" aria-controls="abg_5">
				<div class="d-flex">
					<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Administración</span></h5>
				</div>
			</div>
			<div id="abg_5" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Experiencia en la Administración</p>
							<select class="form-control d-block form-control--disabled" disabled v-model="form.experienciaAdministracion">'
								<option value="" disabled></option>
								<option value="De 0 a 5 años">De 0 a 5 años</option>
								<option value="De 6 a 10 años">De 6 a 10 años</option>
								<option value="Mas de 10 años">Mas de 10 años</option>
							</select>
						</label>
						<div class="py-2" v-if="form.experienciaAdministracion">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios de Experiencia de la Administración</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosExperienciaAdministracion">
								</textarea>
							</label>
						</div>
						
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Agresividad de descisiones de riesgo</p>
							<select class="form-control d-block form-control--disabled" disabled  v-model="form.agresividadTomaDecisionesRiesgo">'
								<option value=""></option>
								<option value="De 0 a 5 años">Agresivo</option>
								<option value="De 6 a 10 años">Medio</option>
								<option value="Mas de 10 años">Conservador</option>
							</select>
						</label>
					</div>
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span>  Tipo de Administración</p>
							<select class="form-control d-block form-control--disabled" disabled  v-model="form.tipoAdministracion">'
								<option value=""></option>
								<option value="Administrador Único">Administrador Único</option>
								<option value="Institucional">Institucional</option>
								<option value="Familiar">Familiar</option>
							</select>
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_6" aria-expanded="true" aria-controls="abg_6">
				<div class="d-flex">
					<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Principales filiales y participadas</span></h5>
				</div>
			</div>
			<div id="abg_6" class="collapse" aria-labelledby="headingOne">
				<div class="py-2">
					<p v-if="!filiales.length">No se encontraron  registros</p>
					<div v-else class="py-2">
						<table class="s-table w-100 text-center">
							<thead>
								<tr>
									<th>NOMBRE</th>
									<th>ACTIVIDAD</th>
									<th>PATICIPACIÓN ACCIONARIA</th>
									<th>CLIENTE BBVA</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(f, index) in filiales">
									<td>
										{{f.nombre}}
									</td>
									<td>
										{{f.actividad}}
									</td>
									<td>
										{{f.participacion}}%
									</td>
									<td>
										{{f.clienteBBVA}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="d-flex flex-wrap mx-n2">
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Comentarios filiales / paricipadas</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosFiliales">
							</textarea>
						</label>
					</div>
				</div>
			</div>
		</div>

		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formAnalysisGroupModal" tabindex="-1" role="dialog" aria-labelledby="formAnalysisGroupModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="s-collapse__header" data-toggle="collapse" data-target="#abg_m_1" aria-expanded="true" aria-controls="abg_m_1">
						<div class="d-flex">
							<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Path</span></h5>
						</div>
					</div>
					<div id="abg_m_1" class="collapse show" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">
							<div class="col-12 col-md-6">
								
								<div class="h-100 py-2">
									<p class="m-0"><span class="text-danger">*</span> Template</p>
									<div>
										<select class="form-control" name="" v-model="form.path">
											<option value=""></option>
											<option value="Generico">Generico</option>
											<option value="Construcción">Construcción</option>
											<option value="Hoteles">Hoteles</option>
											<option value="Agro">Agro</option>
											<option value="Retail">Retail</option>
											<option value="Auto Retail">Auto Retail</option>
										</select>
									</div>
									<div v-if="showHelp" class="alert alert-warning" role="alert">
										<b>Nota:</b> El campo Path no debe ser editado es un campo temporal que no será visible en la versión final de ARCE.
									</div>
								</div>
							</div>
							
						</div>
					</div>
					<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_m_2" aria-expanded="true" aria-controls="abg_m_2">
						<div class="d-flex">
							<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Cromo Grupo</span></h5>
						</div>
					</div>
					<div id="abg_m_2" class="collapse" aria-labelledby="headingOne">
					</div>
					<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_m_3" aria-expanded="true" aria-controls="abg_m_3">
						<div class="d-flex">
							<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Identificaciones</span></h5>
						</div>
					</div>
					<div id="abg_m_3" class="collapse" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Nombre del Grupo</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="group.name">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">País de Cabecera</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="group.pais_cabecera">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Número de Grupo Económico</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="group.code">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Sector (asset allocation)</p>
									<input class="form-control form-control--disabled" disabled type="text">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Cliente desde</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="group.clientSince">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">¿Tiene marca de Leverage el análisis anterior?</p>
									<input class="form-control form-control--disabled" disabled type="text" value="SI">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Clasificación de Grupo</p>
									<input class="form-control form-control--disabled" disabled type="text" value="">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Grupo Cualitativo</p>
									<input class="form-control form-control--disabled" disabled type="text" value="SI">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block">
									<p class="m-0"><span class="text-danger">*</span> Grupo Reestructurado o refinanciado</p>
									<div class="s-tf">
										<label class="s-tf__item">
											<input type="radio" name="abg_gror" value="SI" v-model="form.reestructuradoRefinanciado">
											<span class="s-tf__btn">SI</span>
										</label>
										<label class="s-tf__item" >
											<input type="radio" name="abg_gror" value="NO" v-model="form.reestructuradoRefinanciado">
											<span class="s-tf__btn">NO</span>
										</label>
									</div>
								</label>
								<div v-if="form.reestructuradoRefinanciado == 'SI'" class="py-2">
									<label class="d-block m-0">
										<p class="m-0"> <span class="text-danger">*</span> Comentarios sobre el grupo reestructurado o refinanciado</p>
										<textarea class="form-control" type="text" v-model="form.reestructuradoRefinanciadoComentarios">
										</textarea>
										<characters-available :max="500" :text="form.reestructuradoRefinanciadoComentarios"></characters-available>

									</label>
								</div>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Reserva (%)</p>
									<input class="form-control form-control--disabled" disabled type="text" value="12 %">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">RAR - Rentablilidad</p>
									<input class="form-control" type="text" v-model="form.rarRentabilidad">
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_m_4" aria-expanded="true" aria-controls="abg_m_4">
						<div class="d-flex">
							<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Accionistas</span></h5>
						</div>
					</div>
					<div id="abg_m_4" class="collapse" aria-labelledby="headingOne">
						<div class="py-3 px-2">
							<table class="s-table w-100">
								<thead class="text-center">
									<tr>
										<th>Accionista</th>
										<th v-for="(c) in getClients()">
											{{c}}
										</th>
									</tr>
								</thead>
								<tbody class="text-center">
									<tr v-for="sh in getShareholders()">
										<td>
											{{sh.nombre}}
										</td>
										<td v-for="(c) in getClients()">
											{{getHolder(sh, c)}}%
										</td>
									</tr>
									<tr>
										<td>TOTAL</td>
										<td v-for="(c) in getClients()">
											{{getHolderTotal(c)}}%
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentario Detalle Accionista</p>
									<textarea class="form-control" type="text" v-model="form.comentarioDetalleAccionistas">
									</textarea>
									<characters-available :max="500" :text="form.comentarioDetalleAccionistas"></characters-available>

								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> ¿Posibles discrepancias / conflictos entre los accionistas? </p>
									<div class="s-tf">
										<label class="s-tf__item">
											<input type="radio" name="abg_pca" value="SI" v-model="form.conflictosEntreAccionistas">
											<span class="s-tf__btn">SI</span>
										</label>
										<label class="s-tf__item" >
											<input type="radio" name="abg_pca" value="NO" v-model="form.conflictosEntreAccionistas">
											<span class="s-tf__btn">NO</span>
										</label>
									</div>
								</label>
								<div class="py-2" v-if="form.conflictosEntreAccionistas == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios Posibles discrepancias / conflictos entre los accionistas</p>
										<textarea class="form-control" type="text" v-model="form.comentariosConflictosEntreAccionistas">
										</textarea>
										<characters-available :max="500" :text="form.comentariosConflictosEntreAccionistas"></characters-available>
										
									</label>
								</div>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> ¿Movimientos significativos en el accionario del grupo? </p>
									<div class="s-tf">
										<label class="s-tf__item">
											<input type="radio" name="abg_msag" value="SI" v-model="form.movimientoSignificativoAccionario">
											<span class="s-tf__btn">SI</span>
										</label>
										<label class="s-tf__item" >
											<input type="radio" name="abg_msag" value="NO" v-model="form.movimientoSignificativoAccionario">
											<span class="s-tf__btn">NO</span>
										</label>
									</div>
								</label>
								<div class="py-2" v-if="form.movimientoSignificativoAccionario == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios Movimientos significativos en el accionario del grupo</p>
										<textarea class="form-control" type="text" v-model="form.comentariosMovimientoSignificativoAccionario">
										</textarea>
										<characters-available :max="500" :text="form.comentariosMovimientoSignificativoAccionario"></characters-available>
										
									</label>
								</div>
							</div>
							<div class="col-4  py-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> ¿Respaldo de los Créditos por parte de loas Accionistas?</p>
									<select class="form-control" type="text" v-model="form.respaldoCreditoAccionistas">
										<option value=""></option>
										<option value="NO">NO</option>
										<option value="Accionistas">Accionistas</option>
										<option value="Grupo">Grupo</option>
										<option value="Garantía de activos">Garantía de activos</option>
										<option value="Garantía hipotecaria">Garantía hipotecaria</option>
										<option value="Desconocido">Desconocido</option>
									</select>
								</label>
							</div>
							<div class="col-4  py-2" v-if="form.respaldoCreditoAccionistas != 'NO'">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentarios sobre el apoyo de los accionistas</p>
									<textarea class="form-control" type="text" v-model="form.comentariosRespaldoCreditoAccionistas">
									</textarea>
									<characters-available :max="500" :text="form.comentariosRespaldoCreditoAccionistas"></characters-available>
									
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_m_5" aria-expanded="true" aria-controls="abg_m_5">
						<div class="d-flex">
							<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Administración</span></h5>
						</div>
					</div>
					<div id="abg_m_5" class="collapse" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Experiencia en la Administración</p>
									<select class="form-control d-block" v-model="form.experienciaAdministracion">'
										<option value="" disabled></option>
										<option value="De 0 a 5 años">De 0 a 5 años</option>
										<option value="De 6 a 10 años">De 6 a 10 años</option>
										<option value="Mas de 10 años">Mas de 10 años</option>
									</select>
								</label>
								<div class="py-2" v-if="form.experienciaAdministracion">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios de Experiencia de la Administración</p>
										<textarea class="form-control" type="text" v-model="form.comentariosExperienciaAdministracion">
										</textarea>
										<characters-available :max="500" :text="form.comentariosExperienciaAdministracion"></characters-available>
										
									</label>
								</div>
								
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Agresividad de descisiones de riesgo</p>
									<select class="form-control d-block"  v-model="form.agresividadTomaDecisionesRiesgo">'
										<option value=""></option>
										<option value="De 0 a 5 años">Agresivo</option>
										<option value="De 6 a 10 años">Medio</option>
										<option value="Mas de 10 años">Conservador</option>
									</select>
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span>  Tipo de Administración</p>
									<select class="form-control d-block"  v-model="form.tipoAdministracion">'
										<option value=""></option>
										<option value="Administrador Único">Administrador Único</option>
										<option value="Institucional">Institucional</option>
										<option value="Familiar">Familiar</option>
									</select>
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header collapsed my-2" data-toggle="collapse" data-target="#abg_m_6" aria-expanded="true" aria-controls="abg_m_6">
						<div class="d-flex">
							<h5 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Principales filiales y participadas</span></h5>
						</div>
					</div>
					<div id="abg_m_6" class="collapse" aria-labelledby="headingOne">
						<div class="py-2">
							<p v-if="!filiales.length">No se encontraron  registros</p>
							<div v-else class="py-2">
								<table class="s-table w-100 text-center">
									<thead>
										<tr>
											<th>NOMBRE</th>
											<th>ACTIVIDAD</th>
											<th>PATICIPACIÓN ACCIONARIA</th>
											<th>CLIENTE BBVA</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(f, index) in filiales">
											<td>
												{{f.nombre}}
											</td>
											<td>
												{{f.actividad}}
											</td>
											<td>
												{{f.participacion}}%
											</td>
											<td>
												{{f.clienteBBVA}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div>
								<button class="s-btn s-btn--primary s-btn--outline" @click="showFilialModal">Administrar Principals filiales y participadas</button>
							</div>
						</div>
						<div class="d-flex flex-wrap mx-n2">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentarios filiales / paricipadas</p>
									<textarea class="form-control" type="text" v-model="form.comentariosFiliales">
									</textarea>
									<characters-available :max="500" :text="form.comentariosFiliales"></characters-available>

								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>

		<!-- ============================= -->
		<!-- ============================= -->
		<!-- ============================= -->
		<div class="modal s-modal fade" id="filialModal" tabindex="-1" role="dialog" aria-labelledby="filialModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title w-100 text-center" id="filialModalLabel">Administrar Accionistas</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="bg-secondary text-center text-white" v-if="!filialesForm.length">
						The table is empty
					</div>
					<div class="d-flex" v-for="(f, index) in filialesForm" :key="`fl_${index}`">
						<div class="col-3 mt-auto">
							<div class="position-relative">
								<p class="text-center m-0">
									Nombre
								</p>
								<div class="position-relative">
									<div class="s-input" data-v-step="cs1">
										<input v-if="!f.nombre" class="form-control w-100" type="text" v-model="f.query">
										<input v-else class="form-control w-100" disabled type="text" v-model="f.nombre">
										<svg-icon class="mx-2" name="search" />
										<span class="m-0" @click="f.nombre = ''" v-if="f.nombre">
											<svg-icon class="mx-2 s-input__delete" name="close" />
										</span>
									</div>
									
								</div>
								<div v-if="f.query" class="s-query-result">
									<div class="d-flex" @click="f.nombre = f.query, f.query = ''">
										<button class="s-query-btn my-auto">
											<svg-icon name="add" />
										</button>
										<div class="ml-2 my-auto">
											<p class="small m-0">Create new arce...</p>
											<p class="m-0 small">{{f.query}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-2 mt-auto">
							<p class="m-0"> 
								% de Participación
							</p>
							<input class="form-control w-100" type="text" v-model="f.participacion">
						</div>
						<div class="col-3 mt-auto">
							<p class="m-0"> 
								Actividad
							</p>
							<input class="form-control w-100" type="text" v-model="f.actividad">
						</div>
						<div class="col-3 mt-auto">
							<p class="m-0"> 
								Cliente BBVA
							</p>
							<select class="form-control w-100" type="text" v-model="f.clienteBBVA">
								<option value="">--Ninguno--</option>
								<option value="SI">SI</option>
								<option value="NO">NO</option>
							</select>
						</div>
						<div class="col-1 mt-auto">
							<button v-if="filialesForm.length > 1" @click="deleteItem(index)" class="s-btn s-btn--transparent">
								<svg-icon name="delete" />
							</button>
						</div>
					</div>
					<div class="text-right">
						<button @click="addNew" class="s-query-btn my-auto mr-5">
							<svg-icon name="add" />
						</button>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" @click="saveAllSubsidiary" class="s-btn s-btn--primary">Save all</button>
				</div>
				</div>
			</div>
		</div>
		
	</div>
</template>
